.term__input {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding: 10px;

    .project__heading {
        /* width: 160px; */

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        color: var(--Primary, #D0006E);
    }

    .term__btns {
        display: flex;
        align-items: center;
        gap: 20px;
    }
}

@media (max-width:900px) {
    .term__input {
        .input__field {
            max-width: 260px;
        }
    }
}

@media (max-width:682px) {
    .term__input {
        flex-direction: column;
        gap: 24px;
        align-items: start;

        .project__heading {
            width: 100%;
            max-width: 100%;
        }

        .input__field {
            max-width: 100%;
            width: 100%;
        }
    }
}