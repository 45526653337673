.modalBox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    background-color: var(--Light);
    border-radius: 10px;
    padding: 16px;
    outline: none;
}

@media only screen and (min-width: 1024px) {
    .modalBox {
      max-width: 500px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 660px) {
    .modalBox {
      max-width: 80px;
    }
}

@media only screen and (min-width: 960px) and (max-width: 1023px) {
    .modalBox {
      max-width: 450px;
    }
}

@media only screen and (min-width: 768px) and (max-width: 959px) {
    .modalBox {
        max-width: 70%;
    }
}

@media only screen and (min-width: 480px) and (max-width: 767px) {
    .modalBox {
        max-width: 90%;
    }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
    .modalBox {
        max-width: 90%;
    }
}
