.circle_loader {
    height: 20px;
    width: 20px;
    border: 3px solid  var(--primary-400);
    border-top-color: white;
    border-radius: 50%;
    animation: circle_loader 2s forwards linear infinite;
}
@keyframes circle_loader {
    from {
        transform: rotate(0deg);
    } to {
        transform: rotate(360deg);
    }
}