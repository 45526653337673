@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    font-family: 'Poppins', sans-serif;
}

#root,
html,
body {
    width: 100%;
    height: 100vh;
    scroll-behavior: smooth;
    overflow-x: hidden;
}

:root {
    --112D4E: #112D4E;
    --3F72AF: #3F72AF;
    --86BCFC: #86BCFC;
    --DBE2EF: #DBE2EF;
    --F9F7F7: #F9F7F7;

    /* Primary */
    --primary-900: #9C0053;
    --primary-700: #D0006E;
    --primary-400: #FFBFDD;
    --primary-200: #FFE8F2;
    --primary-100: #FFF4F8;

    /* Accent */
    --accent-900: #0055B2;
    --accent-700: #0071ED;
    --accent-400: #81C2FA;
    --accent-200: #BFE3FD;
    --accent-100: #EAF4FD;

    /* Neutral */
    --neutral-900: #13171A;
    --neutral-700: #323337;
    --neutral-400: #4F5054;
    --neutral-200: #6C6D6F;
    --neutral-100: #8A8B8D;
}

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--Primary);
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
span {}

.text-112D4E {
    color: var(--112D4E);
}

.bg-112D4E {
    background-color: var(--112D4E);
}

.text-3F72AF {
    color: var(--3F72AF);
}

.bg-3F72AF {
    background-color: var(--3F72AF);
}

.text-86BCFC {
    color: var(--86BCFC);
}

.bg-86BCFC {
    background-color: var(--86BCFC);
}

.text-DBE2EF {
    color: var(--DBE2EF);
}

.bg-DBE2EF {
    background-color: var(--DBE2EF);
}

.text-F9F7F7 {
    color: var(--F9F7F7);
}

.bg-F9F7F7 {
    background-color: var(--F9F7F7);
}

.cursor-pointer {}

.cursor-text {}

.shadow {
    box-shadow: 0px 4px 30px 0px rgba(17, 45, 78, 0.10);
}

.openProfileModal {
    animation: openProfileModal 0.5s ease-in-out forwards;
}

@keyframes openProfileModal {
    0% {
        opacity: 0;
        max-height: 0;
    }

    100% {
        opacity: 1;
        max-height: 800px;
    }
}

.closeProfileModal {
    animation: closeProfileModal 0.5s ease-in-out forwards;
}

@keyframes closeProfileModal {
    0% {
        opacity: 1;
        max-height: 800px;
    }

    100% {
        opacity: 0;
        max-height: 0;
    }
}

.animation-openClose {
    max-height: 45px;
    /* Initially hide the content */
    transition: max-height 0.4s ease-in-out;
    overflow: hidden;
}

.animation-closeOpen {
    max-height: 800px;
    /* Set a large enough value to accommodate the content */
    transition: max-height 0.8s ease-in-out;
    overflow: hidden;
}

.heading1 {
    font-weight: 500;
    font-size: 42px;
}

.heading3 {
    color: var(--primary-700, #D0006E);

    /* Heading/3 */
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.sliderAuto{
    width: calc(240px * 17);
    animation: sliderAuto;
    animation: sliderAuto 50s infinite;
}

.effect__blue {
    box-shadow: 0 4px 70.5px 0 #BED8F0, inset 70px 103px 70.5px -104px #BED8F0;
}

.effect__pink {
    box-shadow: 0 4px 70.5px 0 #FBD3E1, inset 70px 103px 70.5px -104px #FBD3E1;
}

.blob__blue {
    background-color: var(--accent-200);
    border-radius: 50%;
    position: absolute;
    top: 60vh;
    right: -80px;
    animation: blob 5s ease-in-out infinite;
}

.blob__pink {
    background-color: var(--primary-400);
    border-radius: 50%;
    position: absolute;
    top: -80px;
    left: -80px;
    animation: blob 5s ease-in-out infinite;
}

.btn {
    cursor: pointer;
    padding: 14px 32px;
    border-radius: 18px;
    color: var(--primary-100);
    background: linear-gradient(180deg, #FBD3E195 0%, #FFFFFF00 100%), #D0006E;
}

.btn:hover {
    animation: btnHover .5s 1;
}

.navbar__anime {
    animation: navbarAnime 1s ease-in-out forwards;
}

.anime__left {
    animation: animeLeft 1.4s ease-in-out forwards;
}

.anime__right {
    animation: animeRight 1.4s ease-in-out forwards;
}

.anime__center {
    animation: animeCenter 1s ease-in-out forwards;
}

.left__to__right {
    animation: leftToRight 1s ease-in-out 1 forwards;
}

.review__back {
    background: linear-gradient(90deg, #F3EDF7 0%, rgba(243, 237, 247, 0.80) 8.5%, rgba(255, 255, 255, 0.00) 20%, rgba(255, 255, 255, 0.00) 50%, rgba(255, 255, 255, 0.00) 79.5%, rgba(243, 237, 247, 0.80) 91.5%, #F3EDF7 100%);
}

.right__to__left {
    animation: RightToLeft 1s ease-in-out forwards;
}

@keyframes blob {
    0% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        scale: 1;
    }

    50% {
        border-radius: 100% 0% 34% 66% / 54% 23% 77% 46%;
        scale: 1.2;
    }

    100% {
        border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        scale: 1;
    }
}

@keyframes navbarAnime {
    0% {
        transform: translateY(-140px);
    }

    40% {
        transform: translateY(-140px);
    }

    100% {
        transform: translateY(0px)
    }
}

@keyframes btnHover {
    0% {
        transform: rotate(0deg);
        scale: .9;
    }

    10% {
        transform: rotate(6deg);
        scale: 1;
    }

    20% {
        transform: rotate(-6deg);
        scale: .8;
    }

    30% {
        transform: rotate(6deg);
        scale: 1.1;
    }

    40% {
        transform: rotate(-8deg);
        scale: .9;
    }

    50% {
        transform: rotate(12deg);
        scale: 1;
    }

    60% {
        transform: rotate(-10deg);
        scale: .8;
    }

    70% {
        transform: rotate(6deg);
        scale: 1.1;
    }

    80% {
        transform: rotate(-6deg);
        scale: .9;
    }

    100% {
        transform: rotate(0deg);
        scale: 1;
    }
}

@keyframes animeLeft {
    0% {
        transform: translateX(20px) translateY(20px) rotate(-32deg);
        opacity: 0;
    }

    40% {
        transform: translateX(20px) translateY(20px) rotate(-32deg);
        opacity: 0;
    }

    100% {
        transform: translateX(0px) translateY(0px) rotate(-32deg);
        opacity: 1;
    }
}

@keyframes animeRight {
    0% {
        transform: translateX(-20px) translateY(20px) rotate(32deg);
        opacity: 0;
    }

    40% {
        transform: translateX(-20px) translateY(20px) rotate(32deg);
        opacity: 0;
    }

    100% {
        transform: translateX(0px) translateY(0px) rotate(32deg);
        opacity: 1;
    }
}

@keyframes animeCenter {
    0% {
        transform: translateY(20px) rotate(0deg);
        opacity: 0;
    }

    100% {
        transform: translateY(-36px) rotate(0deg);
        opacity: 1;
    }
}

@keyframes sliderAuto {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-2080px);
    }
}

@keyframes leftToRight {
    0% {
        transform: translateX(-36px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@keyframes RightToLeft {
    0% {
        transform: translateX(36px);
        opacity: 0;
    }

    100% {
        transform: translateX(0px);
        opacity: 1;
    }
}

@media (max-width:1460px) {
    .blob__blue {
        right: -180px;
        top: 78vh;
    }
}

@media (max-width:786px) {
    .heading1 {
        font-size: 36px;
    }
}