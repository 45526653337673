.home__page {
    border-radius: 16px;
    opacity: 0.85;
    background: var(--Light, #D4E7C5);

    margin-top: 10svh;
    margin-bottom: 10svh;
    margin-left: 16vw;
    margin-right: 16vw;

    position: relative;

    padding: 10px;

    min-height: 550px;

    .add__bar {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        border-radius: 10px;
        background: var(--White, #E1F0DA);

        padding: 10px;
        box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    }

    .add__btn {
        display: flex;
        padding: 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;

        cursor: pointer;

        border-radius: 12px;
        background: var(--Primary, #D0006E);

        width: fit-content;

        >span {
            color: #FFF;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .first__time {

        max-width: 556px;

        display: flex;
        flex-direction: column;
        gap: 24px;
        align-items: center;

        position: absolute;
        top: 55%;
        left: 50%;
        transform: translate(-50%, -50%);

        .first__heading {
            color: var(--Primary, #D0006E);
            text-align: center;
            font-family: Raleway;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .first__paragraph {
            color: var(--Primary, #D0006E);
            text-align: center;
            font-family: Raleway;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    .projects__div {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 20px;

        .project__div {

            padding: 10px;

            display: flex;
            align-items: center;
            justify-content: space-between;

            .languages__div {
                display: flex;
                align-items: center;
                gap: 10px;

                overflow: hidden;
                width: 100px;

                >img {
                    cursor: pointer;
                    max-width: 24px;
                }
            }

            .project__heading {
                cursor: pointer;

                width: 160px;

                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                color: var(--Primary, #D0006E);
            }
        }
    }

}

.project__name {
    color: #D0006E;
    font-weight: 600;
    font-size: 20px;
}

@media (max-width: 1100px) {
    .home__page {
        margin-left: 6vw;
        margin-right: 6vw;

        .first__time {
            padding: 10px;

            .first__heading {
                font-size: 26px;
            }

            .first__paragraph {
                font-size: 14px;
            }
        }

        .projects__div {
            .project__div {
                .project__heading {
                    width: 100px;
                    font-size: 14px;
                }

                .languages__div {
                    gap: 10px;
                    width: 70px;

                    >img {
                        width: 16px;
                    }
                }
            }
        }
    }
}


.input__field {
    display: flex;
    padding: 16px 20px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 16px;

    background-color: var(--White);

    width: 398px;

    border: 0;
    outline: none;

    color: var(--Primary, #99BC85);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.input__field::placeholder {
    color: var(--Primary, #99BC85);
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.textarea__field {
    resize: none;
}