.nav__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 3dvh 6vw;

    background: var(--Primary, #424874);

    >img {
        cursor: pointer;
    }

    >img:last-child {
        cursor: pointer;
        width: 62px;
        transition: .3s;

        border: 1px solid transparent;
        border-radius: 50%;
    }

    >img:last-child:hover {
        transform: scale(1.1);

        border: 1px solid var(--Primary);
    }
}


@media (max-width:650px) {
    .nav__bar {
        >img:last-child {
            border-radius: 50%;
            max-width: 42px;
        }
    }
}