.login__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    background: var(--Light);
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 16px;

    padding: 36px;

    .form__heading {
        color: var(--Primary, #D0006E);
        text-align: center;
        font-family: Raleway;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        max-width: 278px;

        >span {
            font-weight: 700;
        }
    }

    .form__fields {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .form__text {
            max-width: 398px;
            cursor: auto;
        }
    }

    .password_input_field{
        position: absolute;
        right: 30px;
        top:207px;
        border: none;
        cursor: pointer;
        padding: 0 20px;
    }

    .forget__remember {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: end;

        .remember__div {
            display: flex;
            align-items: center;
            gap: 16px;
        }

        >.form__text {
            text-decoration: underline;
        }
    }

    .form__text {
        color: var(--Primary, #99BC85);
        font-family: Raleway;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        cursor: pointer;

        >span {
            text-decoration: underline;
        }
    }
}

.form__btn {
    display: flex;
    width: 100%;
    padding: 12px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    cursor: pointer;

    border-radius: 16px;
    background: var(--Primary, #D0006E);

    color: #FFF;
    font-family: Raleway;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.check__box {
    width: 20px;
    height: 20px;
    border-radius: 7px;
    background: #FFF;
    padding: 4px;

    cursor: pointer;

    .checkbox__inner {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        background: var(--Primary, #99BC85);
    }
}

@media (max-width: 540px) {
    .login__form {
        gap: 24px;

        padding: 16px;

        .form__heading {
            font-size: 24px;
            max-width: 200px;
        }

        .form__fields {
            gap: 16px;

            .input__field {
                font-size: 14px;

                padding: 16px 12px;
                width: 300px;
            }

            .input__field::placeholder {
                font-size: 14px;
            }
        }

        .forget__remember {
            .remember__div {
                gap: 4px;
            }
        }

        .form__text {
            font-size: 16px;
        }
    }
}