.percentage-bar {
    width: 100%;
    background-color: #e0e0df;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
  }
  
  .percentage-bar__fill {
    height: 30px;
    line-height: 30px;

    /* background-color: #76c7c0; */
    background-color: var(--primary-700, #D0006E);
    color: white;
    text-align: center;
    transition: width 0.5s ease-in-out;
  }
  